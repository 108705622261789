import.meta.glob([
    '../images/**',
    '../fonts/**',
]);

import './bootstrap';

import 'admin-lte';


//import 'overlayscrollbars/overlayscrollbars.css';
import {
    OverlayScrollbars,
    ScrollbarsHidingPlugin,
    SizeObserverPlugin,
    ClickScrollPlugin
} from 'overlayscrollbars';


document.addEventListener("DOMContentLoaded", function () {
    const sidebarWrapper = document.querySelector('.sidebar-wrapper');
    if (sidebarWrapper) {
        OverlayScrollbars(sidebarWrapper, {
            scrollbars: {
                theme: 'os-theme-light',
                autoHide: 'leave',
                clickScroll: true,
            },
        });
    }
});


/**
 *  global helper functions
 * 
 */

function URLSetParam(key, value) {

    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set(key, value);
    history.replaceState(null, null, "?" + queryParams.toString());
    location.reload();

}


window.URLSetParam = URLSetParam;


